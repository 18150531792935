<template>
	<div id="ap">
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="4">
							<common-page-btn @save="$refs.recordForm._show()">
								<template slot='mySlot'>
									<a-button v-has="'goods:stock'" @click="Excel">报表导出</a-button>
								</template>
							</common-page-btn>
						</a-col>
						<a-col span="18">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="4">
									<a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
										@on-clear="doRefresh" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="4" v-has="'goods:audit'">
									<select-depart multiple v-model="param.depIds" :organ-id="param.organId"
										@change="doRefresh()"></select-depart>
								</a-col>
								<a-col span="4">
									<a-range-picker :placeholder="['开始日期', '截止日期']" @change="setDateRange" />
								</a-col>
								<a-col span="2">
									<a-select v-model="param.categoryId" style="width:100%" @change="doRefresh()">
										<a-select-option value="">所有分类</a-select-option>
										<a-select-option v-for="(item, index) in categoryList" :key="index"
											:value="item.categoryId">{{
												item.name }}</a-select-option>
									</a-select>
								</a-col>
								<a-col span="2">
									<a-select v-model="param.type" style="width:100%" @change="doRefresh()">
										<a-select-option value="">所有类型</a-select-option>
										<a-select-option value="0" v-has="'goods:audit'">采购</a-select-option>
										<a-select-option value="1">领用</a-select-option>
										<a-select-option value="2">借用</a-select-option>
										<a-select-option value="3">归还</a-select-option>
										<a-select-option value="4" v-has="'goods:audit'">报废</a-select-option>
									</a-select>
								</a-col>
								<a-col span="2">
									<a-select v-model="param.status" style="width:100%" @change="doRefresh()">
										<a-select-option value="">所有状态</a-select-option>
										<a-select-option value="0">待审批</a-select-option>
										<a-select-option value="1">已通过</a-select-option>
										<a-select-option value="2">未通过</a-select-option>
									</a-select>
								</a-col>

								<a-col span="6" v-if="getOrganId() == 0">
									<a-select v-model="param.organId" style="width:100%" @change="e => {
										this.getCategoryList();
										this.doRefresh();
									}
										" placeholder="根据单位筛选">
										<a-select-option value="">所有单位</a-select-option>
										<a-select-option :value="organ.organId" v-for="(organ, index) in organList"
											:key="index">{{ organ.name
											}}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-layout>
			<a-layout-content>
				<a-table id="table" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
					:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="id" :columns="keys"
					ref="list" :loading="loading" :data-source="data.records">
					<!-- 状态 -->
					<template slot-scope="row" slot="status">
						<a-tooltip title="待审批">
							<a-icon v-if="row.status == '待审批'" type="question-circle" />
						</a-tooltip>
						<a-tooltip :title="`未通过,原因：${row.cause}`">
							<a-icon v-if="row.status == '未通过'" style="color:red" type="stop" />
						</a-tooltip>
						<a-tooltip title="已通过"><a-icon style="color:#090" v-if="row.status == '已通过'"
								type="check-circle" /></a-tooltip>
					</template>
					<template slot-scope="row" slot="remark">
						<a-tooltip :title="row.remark">{{ row.remark }}</a-tooltip>
					</template>
					<!--操作-->
					<template slot-scope="row" slot="action">
						<a-button-group shape="circle" size="small">
							<a-tooltip title="审批" v-if="row.status == '待审批'"><a-button type="link" v-has="'goods:audit'"
									icon="check" @click="showModal(row)"></a-button></a-tooltip>
							<a-tooltip title="修改"><a-button type="link" v-has="'goods:audit'" icon="edit"
									@click="showEdit(row)"></a-button></a-tooltip>
							<a-tooltip title="删除"><a-button type="link" icon="delete" @click="del(row.id)"
									:disabled="(row.status == '已通过' && user.name != row.auditName)"></a-button></a-tooltip>
						</a-button-group>
					</template>

					<template slot="footer" v-if="footer.length > 0">
						<div style="width:100%;display: flex;justify-content: center;font-weight: bold;"
							v-has="'goods:audit'">
							<span v-for="(text, index) in footer" :key="index" style="margin-right:10px">
								{{ text }}
							</span>
						</div>
					</template>
				</a-table>
			</a-layout-content>
		</a-layout>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination show-quick-jumper :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" show-size-changer :current="data.current"
					:page-size-options="['10', '20', '30', '40', '50', '100']">
					<template slot="buildOptionText" slot-scope="props">
						<span>{{ props.value }}条/页</span>
					</template>
				</a-pagination>
			</a-col>
		</a-row>

		<!-- 审批 -->
		<div>
			<a-modal title="审批" cancelText="取消审批" okText="确认审批" :visible="visible" :confirm-loading="confirmLoading"
				@ok="handleOk" @cancel="handleCancel">
				<a-form-model>
					<a-row :gutter="15">
						<a-col span="8">
							<a-form-model-item label="请选择是否通过审批" label-position="top">
								<a-switch v-model="isExamine" />
							</a-form-model-item>
						</a-col>
						<a-col span="8">
							<a-form-model-item v-if="!isExamine" label="不通过原因" label-position="top">
								<a-input v-model="cause" placeholder="请输入不通过原因"></a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
			</a-modal>
		</div>
		<a-modal title="修改记录" cancelText="取消" okText="确认" :visible="showEditModal" :confirm-loading="confirmLoading"
			@ok="handleEdit" @cancel="showEditModal = false">
			<a-form-model>
				<a-row :gutter="15">
					<a-col span="24">
						<a-form-model-item label="物品名称" label-position="top">
							<a-input v-model="editData.goodsName" disabled />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="15">
					<a-col span="8">
						<a-form-model-item label="登记人">
							<a-input v-model="editData.staffName" disabled />
						</a-form-model-item>
					</a-col>
					<a-col span="8">
						<a-form-model-item label="数量" label-position="top">
							<a-input v-model="editData.number" disabled>
								<template slot="addonAfter">
									{{ editData.mode }}
								</template>
							</a-input>
						</a-form-model-item>
					</a-col>
					<a-col span="8">
						<a-form-model-item label="登记类型" prop="type">
							<a-radio-group v-model="editData.type" button-style="solid"
								:disabled="!['领用', '借用'].includes(editData.type)">
								<a-radio-button value="领用">领用</a-radio-button>
								<a-radio-button value="借用">借用</a-radio-button>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="15">
					<a-col span="24">
						<a-form-model-item label="备注" label-position="top">
							<a-input v-model="editData.remark" type="textarea" placeholder="备注信息"></a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>


		<record-form ref="recordForm" @success="doRefresh"></record-form>
		<export-excel ref="ExportExcel" :categoryList="categoryList"></export-excel>
	</div>
</template>

<script>
import { mapState } from "vuex";
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import recordForm from './components/recordForm.vue';
import request from '@/config/request';
import OMS from "@/common/tools";
import ExportExcel from './components/ExportExcel.vue';
import selectDepart from '@/components/common/select-depart.vue';

export default {
	components: { recordForm, ExportExcel, selectDepart },
	data() {
		return {
			visible: false,
			confirmLoading: false,
			loading: false,
			isExamine: false,
			cause: '',
			id: '',
			organList: apiUtil.getOrganList(),
			depList: [],
			param: {
				page: 1,
				limit: 10,
				organId: apiUtil.getOrganId(''),
				depId: '',
				keyword: '',
				categoryId: '',
				startDate: '',
				endDate: '',
				type: '',
				status: '',
				depIds: []
			},
			data: {},
			keys: [
				{ title: '物品名称', dataIndex: 'goodsName', ellipsis: true, align: 'left' },
				{ title: '登记人', dataIndex: 'staffName', width: 100, ellipsis: true, align: 'center' },
				{ title: '登记类型', dataIndex: 'type', width: 100, ellipsis: true, align: 'center' },
				{ title: '数量', customRender: (res) => { return res.number + '' + res.mode; }, align: 'center', width: 80, ellipsis: true },
				{ title: '单价', customRender: (res) => { return '￥' + res.goodsPrice.toFixed(2) }, align: 'center', width: 100, ellipsis: true },
				{ title: '合计', customRender: (res) => { return '￥' + (res.goodsPrice * res.number).toFixed(2) }, align: 'center', width: 100, ellipsis: true },
				{ title: '登记时间', dataIndex: 'createTime', width: 160, ellipsis: true, align: 'center' },
				{ title: '备注', scopedSlots: { customRender: 'remark' }, width: 200, ellipsis: true, tooltips: true },
				{ title: '状态', scopedSlots: { customRender: 'status' }, width: 100, ellipsis: true, align: 'center' },
				{ title: '审核人', dataIndex: 'auditName', width: 100, ellipsis: true, align: 'center' },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			],
			categoryList: [],
			showEditModal: false,
			editData: {},
			footer: []
		};
	},

	mounted() {
		this.getCategoryList();
		this.doRefresh();
	},
	computed: {
		...mapState({
			user: (state) => state.account.user
		}),
	},
	methods: {
		...apiUtil,
		getCategoryList() {
			const organId = this.param.organId;
			this.http
				.get(`/platform/goods/category`, { organId: organId })
				.then(ret => {
					this.categoryList = ret.data;
				})
		},
		setDateRange(data, str) {
			this.param.startDate = str[0];
			this.param.endDate = str[1];
			this.doRefresh();
		},
		Excel() {
			this.$refs.ExportExcel.show();

			// OMS.downLoadXlsx({
			// 	dom: 'table',    //获取表格dom，然后拿出数据，所以我需要知道解析哪一个表格
			// 	name: "预报计算结果表",  //excel名字
			// 	// ColumnWdth: [20, 10, 20, 20, 20, 20], //每一列的宽度，需要直接指定，接受数字
			// });

		},
		doRefresh() {
			// console.log('成功');
			this.param.page = 1;
			this.getList();
			this.getFooter();
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/goods/record', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 点击审批
		showModal(e) {
			this.id = e.id;
			this.cause = '';
			this.isExamine = true;
			this.visible = true;
		},
		//点击修改
		showEdit(e) {
			this.editData = JSON.parse(JSON.stringify(e));
			this.showEditModal = true;
		},
		//修改记录
		handleEdit() {
			utils.showSpin();
			this.http
				.post(`/platform/goods/record/edit`, this.editData)
				.then(ret => {
					this.utils.success('修改成功');
					this.showEditModal = false;
					this.getList();
				})
		},
		// 删除登记记录
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/goods/record/cancel/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				});
			});
		},

		// 确认审批
		handleOk() {
			// console.log(this.id);
			this.http
				.post(`/platform/goods/record/audit`, { id: this.id, isAgree: this.isExamine, remark: this.cause })
				.then(ret => {
					this.utils.success('审批成功');
					this.visible = false;
					this.getList();
				})
				.catch(err => {
					this.visible = false;
					this.getList();
				});
		},
		// 取消审批
		handleCancel(e) {
			this.visible = false;
		},
		getFooter() {
			console.log(JSON.stringify(this.param));
			this.http.post("/platform/goods/getRecordStock", this.param).then(ret => {
				// console.log(ret)
				this.footer = [
					`入库数量：${ret.data.inNum} 件`,
					`入库金额：￥${ret.data.inMoney} 元`,
					`出库数量：${ret.data.outNum} 件`,
					`出库金额：￥${ret.data.outMoney} 元`,
				];
			})
		}
	}
};
</script>

<style scoped></style>
